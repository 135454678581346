body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(135deg, #1e3c72 0%, #2a5298 100%);
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: #eceff1;
}

#root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

body,
html {
  height: 100%;
  margin: 0;
  background: linear-gradient(135deg, #1e3c72 0%, #2a5298 100%);
  overflow-x: hidden;
}

button,
input,
select,
textarea {
  font-family: inherit;
}

.card-container {
  background-color: #2c3e50;
  color: #eceff1;
}

.card {
  background-color: #34495e;
  color: #eceff1;
}

.card-content {
  background-color: #455a64;
  color: #b0bec5;
}

button {
  background-color: #2196f3;
  color: #fff;
}

.autocomplete-list {
  background-color: #2c3e50;
  color: #eceff1;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 8px 8px;
  z-index: 1000;
  position: absolute;
  width: 100%;
}

.autocomplete-list-item {
  color: #eceff1;
}

body,
html {
  height: 100%;
  margin: 0;
  background: linear-gradient(135deg, #1e3c72 0%, #2a5298 100%);
  overflow-x: hidden;
}

#root,
#content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #1b2a3d;
  color: #eceff1;
}
